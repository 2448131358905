import logo from './logo.svg';
import ReactDOM from 'react-dom';
import React from 'react';

function TechList() {
    return (
        <ul className="TechList">
            <li>React</li>
            <li>Cloudflare</li>
            <li>Azure</li>
            <ul>
                <li>Functions</li>
            </ul>
            <li>AWS</li>
            <ul>
                <li>Lambdas</li>
            </ul>
        </ul>
    );
}

export default TechList;
